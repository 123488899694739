/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.ITC-container .ITC-figure {
  display: flex;
  flex-direction: column; }
  .ITC-container .ITC-figure picture a {
    display: block; }
  .ITC-container .ITC-figure .first {
    order: 1; }
  .ITC-container .ITC-figure .second {
    order: 2;
    margin-top: 1.25rem; }
  .ITC-container .ITC-figure img.stretch {
    width: 100%; }
  .ITC-container .ITC-figure .figcaption {
    z-index: 1; }

.ITC-container .ITC-text-underneath {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between; }
  @media (min-width: 768px) {
    .ITC-container .ITC-text-underneath {
      padding: 0 0 0 8.75rem;
      margin: 0; } }
  .ITC-container .ITC-text-underneath.science-heading h1,
  .ITC-container .ITC-text-underneath.science-heading h2,
  .ITC-container .ITC-text-underneath.science-heading h3,
  .ITC-container .ITC-text-underneath.science-heading h4 {
    font-family: "Suisse Light", "Suisse Book", sans-serif; }
  .ITC-container .ITC-text-underneath.science-heading h3 {
    font-size: 1.5625rem; }
  .ITC-container .ITC-text-underneath.science-heading h4 {
    font-size: 1.25rem; }

.ITC-container .heading-text {
  width: 100%; }

.ITC-container .heading-btns {
  width: 100%;
  margin-top: 0.5625rem;
  display: block; }
  .ITC-container .heading-btns .btn {
    margin-bottom: 0.625rem; }
    .ITC-container .heading-btns .btn:first-child {
      margin-right: 1rem; }

@media (min-width: 768px) {
  .ITC-container.horizontal .ITC-figure,
  .ITC-container.horizontal .video-component--image {
    flex-direction: row; }
    .ITC-container.horizontal .ITC-figure picture,
    .ITC-container.horizontal .ITC-figure video,
    .ITC-container.horizontal .ITC-figure .video-component,
    .ITC-container.horizontal .video-component--image picture,
    .ITC-container.horizontal .video-component--image video,
    .ITC-container.horizontal .video-component--image .video-component {
      width: 50%; }
    .ITC-container.horizontal .ITC-figure figcaption,
    .ITC-container.horizontal .video-component--image figcaption {
      display: flex;
      align-items: center;
      width: 50%; } }

.ITC-container.horizontal .ITC-text {
  padding: 2rem 3rem; }
  @media (min-width: 768px) {
    .ITC-container.horizontal .ITC-text.text-left {
      padding: 2rem 7rem 2rem 3rem; }
    .ITC-container.horizontal .ITC-text.text-right {
      padding: 2rem 3rem 2rem 7rem; }
    .ITC-container.horizontal .ITC-text.text-center {
      padding: 2rem 5rem; } }

@media (min-width: 768px) {
  .ITC-container.text-slider .ITC-figure {
    display: flex; }
    .ITC-container.text-slider .ITC-figure picture {
      width: 50%; }
    .ITC-container.text-slider .ITC-figure figcaption {
      display: flex;
      align-items: center;
      width: 50%; }
    .ITC-container.text-slider .ITC-figure .first {
      order: 1; }
    .ITC-container.text-slider .ITC-figure .second {
      order: 2; } }

.ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text {
  padding: 2rem 3rem; }
  @media (min-width: 768px) {
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-left {
      padding: 2rem 5rem 2rem 4rem; }
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-right {
      padding: 2rem 4rem 2rem 5rem; }
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-center {
      padding: 2rem 5rem; } }

.ITC-container.light .ITC-figure {
  background-color: var(--skin-bg-dark); }

.ITC-container.light .ITC-text-underneath h1,
.ITC-container.light .ITC-text-underneath h2,
.ITC-container.light .ITC-text-underneath h3,
.ITC-container.light .ITC-text-underneath h4 {
  margin-bottom: 0;
  text-align: inherit; }
  .ITC-container.light .ITC-text-underneath h1:last-of-type,
  .ITC-container.light .ITC-text-underneath h2:last-of-type,
  .ITC-container.light .ITC-text-underneath h3:last-of-type,
  .ITC-container.light .ITC-text-underneath h4:last-of-type {
    margin-bottom: 1rem; }

.ITC-container.light .ITC-text-underneath h1,
.ITC-container.light .ITC-text-underneath h2,
.ITC-container.light .ITC-text-underneath h3,
.ITC-container.light .ITC-text-underneath h4,
.ITC-container.light .ITC-text-underneath p {
  color: var(--color-white);
  text-align: inherit; }

.ITC-container.dark .ITC-figure {
  background-color: var(--color-white); }

.ITC-container.dark .ITC-text-underneath h1,
.ITC-container.dark .ITC-text-underneath h2,
.ITC-container.dark .ITC-text-underneath h3,
.ITC-container.dark .ITC-text-underneath h4 {
  margin-bottom: 0;
  text-align: inherit; }
  .ITC-container.dark .ITC-text-underneath h1:last-of-type,
  .ITC-container.dark .ITC-text-underneath h2:last-of-type,
  .ITC-container.dark .ITC-text-underneath h3:last-of-type,
  .ITC-container.dark .ITC-text-underneath h4:last-of-type {
    margin-bottom: 1rem; }

.ITC-container.dark .ITC-text-underneath h1,
.ITC-container.dark .ITC-text-underneath h2,
.ITC-container.dark .ITC-text-underneath h3,
.ITC-container.dark .ITC-text-underneath p {
  color: var(--skin-primary-color); }

.ITC-container.dark .ITC-text-underneath h4 {
  color: var(--color-grey-aa);
  text-align: inherit; }

.ITC-container.no-bg .ITC-figure {
  background-color: transparent; }

.ITC-container .ITC-figure.text-overlay figcaption {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }
  .ITC-container .ITC-figure.text-overlay figcaption .ITC-text-underneath {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    margin: 0;
    max-width: 100%;
    padding: 0; }
    @media (min-width: 768px) {
      .ITC-container .ITC-figure.text-overlay figcaption .ITC-text-underneath {
        width: auto; } }
  .ITC-container .ITC-figure.text-overlay figcaption.top .ITC-text-underneath {
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .ITC-container .ITC-figure.text-overlay figcaption.right .ITC-text-underneath {
    right: 2rem;
    transform: translateY(-50%);
    text-align: right; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .ITC-container .ITC-figure.text-overlay figcaption.left .ITC-text-underneath {
    top: 50%;
    left: 2rem;
    transform: translateY(-50%); }
  .ITC-container .ITC-figure.text-overlay figcaption.center .ITC-text-underneath {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ITC-container .ITC-figure.text-overlay figcaption.top.right .ITC-text-underneath {
    top: 2rem;
    right: 2rem;
    left: unset;
    transform: unset; }
  .ITC-container .ITC-figure.text-overlay figcaption.top.left .ITC-text-underneath {
    top: 2rem;
    left: 2rem;
    transform: unset;
    text-align: left; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom.right .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    right: 2rem;
    left: unset;
    transform: unset;
    text-align: right; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom.left .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    right: unset;
    left: 2rem;
    transform: unset;
    text-align: left; }
  .ITC-container .ITC-figure.text-overlay figcaption.left.center .ITC-text-underneath {
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    text-align: left; }
    @media (min-width: 1200px) {
      .ITC-container .ITC-figure.text-overlay figcaption.left.center .ITC-text-underneath {
        left: 25%;
        transform: translate(-50%, -50%); } }
  .ITC-container .ITC-figure.text-overlay figcaption.right.center .ITC-text-underneath {
    top: 50%;
    left: unset;
    right: 2rem;
    transform: translateY(-50%);
    text-align: right; }
    @media (min-width: 768px) {
      .ITC-container .ITC-figure.text-overlay figcaption.right.center .ITC-text-underneath {
        right: 25%;
        transform: translate(50%, -50%);
        text-align: left; } }

.ITC-container-optIn .text-micro p {
  font-size: .6875rem;
  line-height: .875rem; }

.content-tile {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden; }
  .content-tile-body.first {
    order: 0; }
  .content-tile-body.last {
    order: 2; }
  .content-tile-cta-container {
    margin: 1.25rem 0;
    flex: 1 0 100%; }
  .content-tile--left, .content-tile-link--left {
    justify-content: flex-start;
    text-align: left; }
  .content-tile--right, .content-tile-link--right {
    justify-content: flex-end;
    text-align: right; }
  .content-tile--center, .content-tile-link--center {
    justify-content: center;
    text-align: center; }
  .content-tile-link {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .content-tile-link:hover {
      text-decoration: none; }
  .content-tile-text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; }
